
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        
















































































/* stylelint-disable declaration-no-important, max-nesting-depth, no-descending-specificity */
.card-small {
  @include bg-color;
}

.card-small__inner {
  padding-top: $spacing * 2.5;
  padding-bottom: $spacing * 4;

  @include mq(m) {
    padding-top: $spacing * 3.5;
    padding-bottom: $spacing * 5;
  }

  @include mq(xl) {
    padding-top: get-spacing(xl, l);
    padding-bottom: get-spacing(l, l);
  }
}

.card-small__description {
  width: col(8);
  margin: $spacing * 0.75 auto $spacing * 1.5;
  font-size: 1.8rem;
  line-height: 1.5;
  text-align: center;

  @include mq(m) {
    font-size: 2rem;
  }

  @include mq(l) {
    margin-bottom: $spacing * 2.5;
    font-size: 2.4rem;
  }
}

.card-small__title {
  margin: 0 0 $spacing * 2.5;
  text-align: center;

  @include mq(m) {
    margin: 0 0 $spacing * 2;
  }
}

.card-small__subtitle {
  margin: -2rem 0 $spacing * 2.5;
  text-align: center;

  @include mq(m) {
    margin: -2rem 0 $spacing * 3;
  }
}

.card-small__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;

  [class*='card-small--'][class*='--double'] & {
    margin: 0 -2rem;
  }

  @include mq(xl) {
    [class*='card-small--'][class*='--double'] & {
      margin: 0 -4rem;
    }
  }
}

.card-small__items__item {
  [class*='card-small--'][class*='--double'] & {
    width: calc(50% - 4rem);
    max-width: none;
    margin: 2rem;
  }

  @include mq(xl) {
    [class*='card-small--'][class*='--double'] & {
      width: calc(50% - 6rem);
      margin: 3rem;
    }
  }
}
